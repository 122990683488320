import { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';

type UseTimerProps = {
  initialTime: number;
  step?: number;
  isCountdown?: boolean;
  onComplete?: (setIsActive: Dispatch<SetStateAction<boolean>>) => void;
};

const useTimer = ({ initialTime, step = 1, isCountdown = true, onComplete }: UseTimerProps) => {
  const [time, setTime] = useState(initialTime);
  const [isActive, setIsActive] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const formatTime = (totalSeconds: number): string => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const start = () => {
    if (!isActive) {
      setIsActive(true);
    }
  };

  const pause = () => {
    if (isActive) {
      if (intervalRef.current) clearInterval(intervalRef.current);
      setIsActive(false);
    }
  };

  const reset = (newTime?: number) => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    setTime(newTime !== undefined ? newTime : initialTime);
    setIsActive(true);
  };

  const reStart = (newTime?: number) => {
    // if (intervalRef.current) clearInterval(intervalRef.current);
    setTime(newTime !== undefined ? newTime : initialTime);
  };

  const resetInfinite = (newTime?: number) => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    setTime(newTime !== undefined ? newTime : initialTime);
    setIsActive(true);
    intervalRef.current = setInterval(() => {
      setTime((prevTime) => {
        const newTime = isCountdown ? prevTime - step : prevTime + step;
        if (newTime <= 0) {
          if (intervalRef.current) clearInterval(intervalRef.current);
          if (onComplete) onComplete(setIsActive);
          return 0;
        }
        return newTime;
      });
    }, 1000);
  };

  useEffect(() => {
    if (isActive) {
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => {
          const newTime = isCountdown ? prevTime - step : prevTime + step;
          if (newTime <= 0) {
            if (intervalRef.current) clearInterval(intervalRef.current);
            if (onComplete) onComplete(setIsActive);
            return 0;
          }
          return newTime;
        });
      }, 1000);

      return () => {
        if (intervalRef.current) clearInterval(intervalRef.current);
      };
    }
  }, [isActive, isCountdown, step, onComplete]);

  return {reStart, time: formatTime(time), isActive, start, pause, reset, resetInfinite };
};

export default useTimer;
