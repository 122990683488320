import { Box, Button, Flex, Input, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { showMSG } from "../utils/utilsFunctions";
import { useTranslation } from "react-i18next";
import usePostData from "../api/usePostData";
import ApiRoutes from "../api/services/api-routes";
import useDeleteData from "../api/useDeleteData";

const ApplyCoupon = ({
  couponData,
  data,
  setData,
  storeId,
  food,
  isCopounApplied,
  index,
}: {
  data: any;
  setData: any;
  storeId: any;
  food?: boolean;
  isCopounApplied: any;
  index: any;
  couponData: any;
}) => {
  const [coupon, setCoupon] = useState<any>(isCopounApplied?.code || "");
  const { t } = useTranslation();
  const toast = useToast();
  // if (appliedCouponStore?.storeId && appliedCouponStore?.storeId !== storeId) {
  //   return <></>;
  // }
  const { postData } = usePostData();
  const { deleteData } = useDeleteData();

  const handleApplyCoupon = async () => {
    if (!coupon?.trim()) {
      showMSG(toast, "error", t("Please enter a coupon code."));
      return;
    }
    try {
      const response = await postData({
        route: food ? ApiRoutes?.applyCouponOnBasket : ApiRoutes?.applyCoupon,
        params: {
          [food ? "basket" : "cart"]: data?.result?._id,
          shop: storeId,
          coupon: coupon,
        },
      });
      console.log(response, "applyyyyyy");
      if (!response?.data?.error) {
        console.log("{...data.result, cartTotal:response?.result?.cartTotal}", {
          ...data.result,
          cartTotal: response?.result?.cartTotal,
        });
        const arrSubCart = [...data?.result?.subCarts];
        arrSubCart[index]["shopTotal"] = response?.result?.cartTotal;
        setData({
          result: {
            ...data.result,
            coupon: response?.result?.coupon,
            couponShop: response?.result?.couponShop,
            cartTotal: response?.result?.cartTotal,
            subCarts: arrSubCart,
          },
        });
        // setAppliedCouponStore && setAppliedCouponStore(response?.data);
        showMSG(toast, "success", "Coupon applied successfully");
      }
      // console.log(response, "applyCoupon");
    } catch (error: any) {
      showMSG(toast, "error", error);
    }
  };

  const CancelCoupon = async () => {
    const res = await deleteData({
      route: food ? ApiRoutes?.cancelFromBasket : ApiRoutes?.cancelCoupon,
      params: { [food ? "basket" : "cart"]: data?.result?._id, shop: storeId },
    });
    if (!res?.data?.error) {
      const arrSubCart = [...data?.result?.subCarts];
      arrSubCart[index]["shopTotal"] = res?.result?.cartTotal;
      setData({
        result: {
          ...data.result,
          coupon: res?.result?.coupon,
          couponShop: res?.result?.couponShop,
          cartTotal: res?.result?.cartTotal,
          subCarts: arrSubCart,
        },
      });
    } else {
      console.log(res);
    }
  };

  return (
    <Flex>
      {(!isCopounApplied || (couponData && isCopounApplied)) && (
        <>
          <Input
            disabled={isCopounApplied}
            borderColor={"#000"}
            h={10}
            mr={2}
            flex={1}
            placeholder={t("Enter Coupon")}
            value={coupon}
            onChange={(e) => {
              setCoupon(e?.target?.value);
            }}
            required
            onClick={(e) => e.stopPropagation()}
          />
          {isCopounApplied && (
            <Button
              // variant="ghost"
              color={"#127346"}
              // onClick={CancelCoupon}
              p={0}
              backgroundColor={"transparent"}
              boxShadow={"none"}
              disabled={true}
              cursor={"not-allowed"}
            >
              {t("Coupon Applied")}
            </Button>
          )}
        </>
      )}
      {!isCopounApplied && (
        <Button onClick={() => handleApplyCoupon()} h={10}>
          {t("Apply now")}
        </Button>
      )}
    </Flex>
  );
};

export default ApplyCoupon;
