import React from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  ResponsiveValue,
  Skeleton,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import Rating from "./Rating";
import { Link } from "react-router-dom";
import { arrowRight2, emptyImage } from "../assets/images";
import { useTranslation } from "react-i18next";
import useLang from "../hooks/useLang";

interface StoreCardProps {
  width?: ResponsiveValue<number | (string & {})>;
  isLoading?: boolean;
  store: {
    nameAr?: string;
    nameEn?: string;
    image?: { Location?: string };
    descriptionAr?: string;
    descriptionEn?: string;
    rating?: number;
    _id: string;
    covers: [{ Location: string }];
  };
}

const StoreCard: React.FC<StoreCardProps> = ({
  isLoading = false,
  store,
  width,
}) => {
  const { t } = useTranslation();
  const { isAr, lang, font } = useLang();
  return (
    <Box
      width={width}
      height={"15em"}
      borderWidth="1px"
      // borderRadius="lg"
      overflow="hidden"
      boxShadow="lg"
      // backgroundColor={"secondary"}
      mb={"16px"}
      position={"relative"}
    >
      <Link to={`/store-details/${store._id}`}>
        <Flex
          onClick={(e) => {
            // e.preventDefault(); // Prevent the default behavior of anchor links
            window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
          }}
          direction={"column"}
          width="100%"
          height={"100%"}
        >
          <Box
            height={"76%"}
            width={"100%"}
            backgroundColor={"#F9F0E3"}
            backgroundImage={store?.covers?.[0]?.Location || emptyImage}
            bgRepeat={"no-repeat"}
            backgroundPosition={"center"}
            backgroundSize={store?.covers?.[0]?.Location ? "cover" : "contain"}
          >
            <Box bg={"rgba(0,0,0,0.5)"} height={"100%"} width={"100%"} px={1}>
              <Flex justify={"center"} pt={5}>
                <Heading
                  style={{ fontFamily: font }}
                  color={"secondary"}
                  as="h4"
                  size="lg"
                  noOfLines={1}
                >
                  {store?.[`name${lang}` as "nameAr" | "nameEn"]}
                  {/* {store?.[`name${lang}`]} */}
                </Heading>
              </Flex>

              <Box position={"absolute"} right={2} bottom={16}>
                <Rating color="#fff" rating={store?.rating || 0} />
              </Box>
              <Box position={"absolute"} width={"100%"} top={140}>
                <Image
                  width={70}
                  height={70}
                  borderRadius={"50%"}
                  src={store?.image?.Location || emptyImage}
                  bgColor={"#eee"}
                  objectFit={"contain"}
                />
              </Box>
            </Box>
          </Box>

          <Box p={2}>
            {/* <Box>
              <Heading as="h6" size="xs" noOfLines={1}>
                {store?.[`name${lang}`]}
              </Heading>
            </Box>
            <Box>
              <Text noOfLines={2} color={"#4F4F4FB2"}>
                {store?.[`description${lang}`]}
              </Text>
            </Box> */}
            <Flex justifyContent={"flex-end"}>
              <Button
                onClick={(e) => {
                  // e.preventDefault(); // Prevent the default behavior of anchor links
                  window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
                }}
                color={"#EAC07F"}
                variant={"secondary"}
              >
                {t("View Store")}
                <Image
                  transform={isAr ? "rotate(180deg)" : ""}
                  src={arrowRight2}
                  width={5}
                />
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Link>
    </Box>
  );
};

export default StoreCard;
