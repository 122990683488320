import React, { useEffect } from "react";
import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Flex,
  Img,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

import useGetData from "../../api/useGetData";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import style from "./style.module.css";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { no_not, person2, bell } from "../../assets/images";
import SocketClass from "../../utils/Socket";
import { setChatMenuData } from "../../redux/reducerSlices/ChatMenu/chatMenu";
import { useNavigate } from "react-router-dom";
import ShowMore from "../../common/ShowMore";
import { useSocket } from "../../Socket/SocketProvider";
import useLang from "../../hooks/useLang";
export default function Notification() {
  const { lang } = useLang();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authInfo = useSelector<RootState, any | undefined>(
    (state) => state?.auth?.authInfo
  );

  const { data, setData, loading, loadMoreData, loadingMoreData } = useGetData({
    notLoadData: !authInfo?.isPhoneVerified,
    route: "notifications/list",
    params: {
      receivers: authInfo && authInfo._id,
      toAdmin: false,
      // seenBy: false,
      sortByDate: "timestamp",
      sortOrder: -1,
      dateField: "timestamp",
      dateFrom: authInfo?.joinDate,
    },
  });

  // const socket = SocketClass.getInstance({ userId: authInfo._id });
  const socket = useSocket();

  const btn_style = {
    background: "transparent",
    boxShadow: "0px 0px 0px transparent",
    color: "black",
    padding: "3px",
    paddingTop: "15px",
    paddingBottom: "15px",
    marginTop: "-4px",
    height: "10px",
    "&:hover": {
      backgroundColor: "#58585826",
      color: "black",
    },
  };

  const notificationListen = () => {
    socket?.on("newNotification", (res: any) => {
      setData((prev: any) => ({
        ...prev,
        count: data?.count + 1,
        result: [res.result, ...prev?.result],
      }));
    });
  };
  useEffect(() => {
    notificationListen();
  }, []);
  const clickMessage = async (item: any) => {
    socket?.emit("markAsRead", { roomId: item.redirectId }, (res: any) => {
      console.log("Acknowledgment Mark Message AsRead from server:", res);
    });
    dispatch(
      setChatMenuData({
        chatMenuData: {
          chatType: item.admin ? "support" : "customers",
          active: true,
          chatMenu: true,
          chatBox: true,
          withAdmin: item.admin ? true : false,
          list: true,
          roomId: item.redirectId,
          fastBox: true,
          sellerId: item?.seller?._id,
        },
      })
    );
    markAsSeen(item._id);
  };

  // const clickOrder = async (item: any) => {
  //   navigate(`/order-details/${item?.redirectId}`);
  //   markAsSeen(item._id);
  // };

  const markAsSeen = (_id: any) => {
    socket?.emit("markAsSeen", { notification: _id }, (res: any) => {
      // setData((prev: any) => ({
      //   ...prev,
      //   count: data?.count - 1,
      //   result: prev.result.filter((items: any, idx: any) => {
      //     return items._id !== _id;
      //   }),
      // }));
    });
  };

  const onClickNotification = (item: any) => {

    markAsSeen(item?._id);
    // const newResult = data?.result?.filter((notification: any) => notification?._id !== item?._id)

    // setData((prev: any) => ({ ...prev, result: newResult }))
    //  return
    if (item?.redirectType === "room") {
      clickMessage(item);

      return;
    }
    if (item?.redirectType === "order") {
      navigate(`/order-details/${item?.redirectId}`);

      return;
    }

    if (item?.redirectType === "serviceRequest") {
      // navigate(`/service-details/${item?.redirectId}`);
      navigate(`/cart/2`);
      // navigate(`/profile/service-request`);

      return;
    }

    // if (item?.redirectType === "serviceRequest") {
    //   navigation.navigate("ServiceRequestDetails", { request: { _id: item?.redirectId, seller: user?._id } })

    //   return;
    // }

    if (item?.redirectType === "shop") {
      navigate(`/store-details/${item?.redirectId}`);

      return;
    }

    if (item?.redirectType === "product") {
      navigate(`/product-details/${item?.redirectId}`);

      return;
    }
    if (item?.redirectType === "service") {
      navigate(`/service-details/${item?.redirectId}`);

      return;
    }
  };

  return (
    <Flex direction="column" alignItems="center">
      <Menu
        isLazy
        lazyBehavior="unmount"
        preventOverflow={true}
        closeOnSelect={true}
        placement="bottom"
      >
        {({ isOpen }) => (
          <>
            <MenuButton
              css={btn_style}
              isActive={isOpen}
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              <Avatar size="xs" src={bell}>
                {data?.count > 0 && (
                  <AvatarBadge
                    top={-2}
                    padding={1}
                    height={"1em"}
                    width={"1em"}
                    alignItems={"center"}
                    bg="red"
                  ></AvatarBadge>
                )}
              </Avatar>
            </MenuButton>
            {isOpen && (
              <Portal>
                <Box
                  position="fixed"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  backgroundColor="rgba(0, 0, 0, 0.5)"
                  zIndex="3"
                />
              </Portal>
            )}
            <MenuList
              paddingTop={"0"}
              paddingBottom={"0"}
              borderRadius={"20px"}
            >
              <Box className={style.list_menu} borderRadius={"20px"}>
                {data?.result?.length > 0 ? (
                  <>
                    {data?.result.map((item: any, index: any) => (
                      <MenuItem
                        padding={"10px"}
                        onClick={() => onClickNotification(item)}
                        style={{
                          backgroundColor: `${
                            item?.seenBy
                              ? "transparent"
                              : "rgb(255, 243, 225)"
                          }`,
                          width: "100%",
                        }}
                      >
                        <Box
                          className={style.li}
                        >
                          <Flex className={style.li_customer}>
                            <Img
                              className={style.li_customer_image}
                              width={"40px"}
                              src={
                                item?.customer?.image?.Location
                                  ? item?.customer?.image?.Location
                                  : person2
                              }
                            />
                            <Box className={style.li_name_title}>
                              <Text className={style.li_customer_name}>
                                {item?.customer?.name}
                              </Text>
                              <Flex className={style.li_title}>
                                {i18n.language === "en"
                                  ? item?.[`title${lang}`]
                                  : item?.[`title${lang}`]}
                              </Flex>
                            </Box>
                          </Flex>
                          <Text
                            textAlign={"justify"}
                            fontSize={"sm"}
                            py={0}
                            color={"lightGray"}
                            className={style.li_body}
                          >
                            {i18n.language === "en" ? item.bodyEn : item.bodyAr}
                          </Text>
                          <Text className={style.li_timestamp}>
                            {moment(item.timestamp).fromNow()}
                          </Text>
                        </Box>
                      </MenuItem>
                    ))}
                    <ShowMore
                      data={!loading && data?.result}
                      loading={loadingMoreData}
                      count={data?.count}
                      loadMoreData={loadMoreData}
                    />
                  </>
                ) : (
                  <Box className={style.no_notifications_content}>
                    <Img width={"50px"} src={no_not} />
                    <Box>{t("No notifications yet")}</Box>
                  </Box>
                )}
              </Box>
            </MenuList>
          </>
        )}
      </Menu>
    </Flex>
  );
}
