import React, { useCallback, useEffect, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../redux/store";

import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { ProductCart } from "./ProductCart/ProductCart";
import { FoodCart } from "./FoodCart/FoodCart";
import { ServiceCart } from "./ServiceCart/ServiceCart";
import { useParams } from "react-router-dom";
const ShoppingCart: React.FC = () => {
  const { t } = useTranslation();

  const { tabNo } = useParams<{
    tabNo: string;
  }>();

  // const [activeTab, setActiveTab] = useState(tabNo ? tabNo : 0);
// Convert tabNo to a number. Use 0 as the default if conversion fails or tabNo is undefined.
const [activeTab, setActiveTab] = useState<number>(
  tabNo ? parseInt(tabNo, 10) || 0 : 0
);

  return (
    <Tabs onChange={(index) => setActiveTab(index)} index={activeTab}>
      <Flex justify={"center"} direction={"column"}>
        <Text
          mt={24}
          fontSize={25}
          fontWeight={"600"}
          color={"primary"}
          align="center"
          mb={8}
        >
          {t("My Cart")}
        </Text>
        <Flex justify={"center"}>
          <TabList width={"40%"} color={"#000"}>
            <Tab
              width={"33%"}
              fontWeight={"600"}
              color={activeTab === 0 ? "primary" : undefined}
            >
              {t("Products")}
            </Tab>
            <Tab
              width={"33%"}
              fontWeight={"600"}
              color={activeTab === 1 ? "primary" : undefined}
            >
              {t("Food Products")}
            </Tab>
            <Tab
              width={"33%"}
              fontWeight={"600"}
              color={activeTab === 2 ? "primary" : undefined}
            >
              {t("Services")}
            </Tab>
          </TabList>
        </Flex>
      </Flex>

      <TabPanels>
        <TabPanel>{activeTab === 0 && <ProductCart />}</TabPanel>
        <TabPanel>{activeTab === 1 && <FoodCart />}</TabPanel>
        <TabPanel>{activeTab === 2 && <ServiceCart />}</TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ShoppingCart;
