import React, { useState } from "react";
import { Box, Flex, Image, Text, Button, Input } from "@chakra-ui/react";
import trash from "../assets/images/imgs/trash.svg";
import plus from "../assets/images/imgs/plus.svg";

import minus from "../assets/images/imgs/minus.svg";
import { emptyImage } from "../assets/images";
import { useTranslation } from "react-i18next";

interface ProductInCartProps {
  productName: string;
  itemTotal: number;
  variationPrice: number;
  variationDesc: string;
  variationImage?: string;
  quantity: number;
  //onDeleteProduct: () => void;
  onDeleteAll: () => void;
  onAddToCart: () => void;
  fields?: [];
  onDeleteProduct: () => {};

  width?: number|string;
  disableAddButton?: boolean;
  disableRemoveButton?: boolean;
}

const ProductInCart: React.FC<ProductInCartProps> = ({
  productName,
  itemTotal,
  variationPrice,
  variationDesc,
  variationImage,
  quantity = 1,
  // onDeleteProduct,
  onDeleteAll,
  onAddToCart,
  fields,
  onDeleteProduct,
  width,
  disableAddButton,
  disableRemoveButton
}) => {
  const {t} = useTranslation()
  const [itemQuantity, setItemQuantity] = useState(quantity);
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    // Remove any non-numeric characters from the input value
    const numericValue = e.target.value.replace(/\D/g, "");
    // Update the itemQuantity state with the numeric value
    setItemQuantity(parseInt(numericValue) || 0); // Convert the numeric value to an integer
  };
  return (
    <Box
      borderBottomWidth="1px"
      borderColor={"#886969"}
      // borderRadius="lg"
      overflow="hidden"
      // boxShadow="lg"
    width={width||"100%"}
      m={2}
      p={3}
      height={190}
    >
      
      <Flex height={"100%"}>
        <Box
          backgroundSize={"contain"}
          backgroundRepeat={"no-repeat"}
          backgroundPosition={"center"}
          backgroundImage={variationImage || emptyImage}
          width={"30%"}
          //  boxSize={"163px"}
          height={"100%"}
          paddingTop={"10"}
          // objectPosition={"right"}
          // aspectRatio={"4/3"}
        ></Box>

        {/* <Image src={variationImage} alt={productName} boxSize="80px" /> */}

        <Box width={"40%"} flex="1" ml={4}>
          <Text fontSize={25} fontWeight="bold" mb={2}>
            {productName}
          </Text>
          <Text color={"primary"} fontSize={22} mb={2}>
            {variationDesc}
          </Text>
          {/* <Text fontSize={22} mb={2}>
            Quantity: {quantity}
          </Text> */}
          <Flex justifyContent={"space-between"}>
            <Text color={"primary"} fontSize={18} mb={2}>
              {t('Price')}: {variationPrice} {t('AED')}
            </Text>
            <Text color={"primary"} fontSize={18} mb={2}>
              {t('Item Total')}: {itemTotal} {t('AED')}
            </Text>
          </Flex>
        </Box>

        <Flex
          width={"30%"}
          direction="column"
          alignItems="flex-end"
          height={"100%"}
        >
          <Flex
            direction={"column"}
            justifyContent={"space-between"}
            height={"100%"}
            alignItems={"flex-end"}
          >
            <Image cursor={"pointer"} src={trash } width={6} onClick={onDeleteAll} />

            <Flex direction={"row"}>
              <Button
                borderColor="#000"
                borderWidth={".5px"}
                backgroundColor={"transparent"}
                borderRightRadius={"0px"}
                fontSize={40}
                px={4}
                py={4}
                color={"primary"}
                cursor={disableRemoveButton ? "not-allowed" : "pointer"}
                disabled={disableRemoveButton}
                onClick={() => {
              
                  onDeleteProduct();
                }}
                bgColor={"secondary"}
              >
                <Image src={minus} />
              </Button>
              <Input
                type="text"
                borderWidth=".5px"
                borderColor={"#000"}
                borderRadius={"0px"}
                width={"70px"}
                textAlign={"center"}
                py={4}
                value={quantity}
                fontSize={22}
                color={"#000"}
                // onChange={handleTextChange}
              />
              <Button
                borderColor="#000"
                borderWidth={".5px"}
                borderLeftRadius={"0px"}
                fontSize={40}
                px={4}
                py={4}
                disabled={disableAddButton}
                cursor={disableAddButton ? "not-allowed" : "pointer"}
                onClick={onAddToCart}
              >
                <Image src={plus} />
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ProductInCart;
