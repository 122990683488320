// TrendingProductCard.tsx

import React from "react";
import {
  Box,
  Image,
  Heading,
  Text,
  Flex,
  Img,
  Center,
  Button,
  ResponsiveValue,
} from "@chakra-ui/react";
import { lang } from "../utils/utilsFunctions";
import Rating from "./Rating";
import expand from "../assets/images/imgs/expand.png";
import favourite from "../assets/images/imgs/favorite.png";
import { Badge } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { emptyImage, reminder } from "../assets/images";
import { compare, sustainabilityIcon, foodIcon } from "../assets/images";
import { eye } from "../assets/images";
import CompareButton from "./CompareButton";
import SavedButton from "./SaveButton";
import { useAppSelector } from "../redux/store";
import useLang from "../hooks/useLang";
type Image = {
  Location?: string;
};
interface Product {
  _id?: number;
  productName?: string;
  productId: string;
  nameEn?: string;
  nameAr?: string;
  descriptionAr?: string;
  descriptionEn?: string;
  //   imageUrl?: string;
  minPackage?: { price?: number; originalPrice: string; quantity: number };
  images?: Array<Image>;
  rating?: number;
  defaultVariation: Product;
  defaultPackage?: { price?: number; originalPrice: number; quantity: number };
  shopName: string;
  variations: Array<any>;
  shop: any;
  product: any;
  isSustainable?: boolean;
  isFood?: boolean;
}

interface TrendingProductCardProps {
  product: Product;
  width?: ResponsiveValue<number | (string & {})>;
  cardType?: string;
}

const TrendingProductCard: React.FC<TrendingProductCardProps> = ({
  product,
  width,
  cardType,
}) => {
  const { t } = useTranslation();

  const url = new URL(window.location.href);
  const hostname = url.hostname; // Domain name
  const pathname = url.pathname; // Path
  const params = url.searchParams; // Query parameters

  const customerId = useAppSelector((state) => state?.auth?.authData?._id);
  const { dir, isAr } = useLang();
  const defaultVariation = product?.product
    ? product
    : product?.defaultVariation || product?.variations?.[0];

  // console.log(
  //   defaultVariation?.defaultPackage?.quantity,
  //   "defaultVariation?.defaultPackage?.quantity "
  // );

  return (
    <Flex
      direction={"column"}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="lg"
      width={width || "90%"}
      height="400px"
      margin={"auto"}
      align="center"
      dir={dir}
    >
      <Box
        width={"100%"}
        height={"60%"}
        backgroundColor="#F9F0E3"
        backgroundImage={defaultVariation?.images?.[0]?.Location || emptyImage}
        bgRepeat="no-repeat"
        backgroundPosition={"center"}
        backgroundSize={"cover"}
        p={2}
        position={"relative"}
      >
        <Flex
          paddingBottom={"32px"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {/* {cardType !== "variation" && ( */}
          <Box>
            {defaultVariation?.defaultPackage?.originalPrice !==
              defaultVariation?.defaultPackage?.price && (
              <Button
                variant={"secondary"}
                borderRadius={"50px"}
                // paddingY={"3px"}
                // paddingX={"14px"}
                height={8}
                color={"#FFFFFF"}
              >
                <Image src={reminder} />
                <Text ml={1}>{t("Sale")}</Text>
              </Button>
            )}
          </Box>
          {/* )} */}
          {customerId !== "guest" && (
            <Flex w={"32%"} justifyContent={"space-between"}>
              <CompareButton _id={defaultVariation?._id} />
              <SavedButton variationId={defaultVariation?._id} />
            </Flex>
          )}
          {/* <Rating rating={product?.rating || 0} /> */}
        </Flex>

        {/* </Center> */}

        {/* </Link> */}
        <Box
          position={"absolute"}
          bottom={1}
          right={2}
          display={"flex"}
          gap={2}
        >
          <Rating
            rating={
              (cardType === "variation"
                ? product?.product?.rating
                : product?.rating) || 0
            }
          />

          {(product?.isFood || product?.product?.isFood) && (
            <Box >
            {/* <Box bg={"#fff"} rounded={"full"} > */}
              <Image src={foodIcon} w={6} h={6}/>
              {/* <Image src={foodIcon} w={6} h={6} p={1}/> */}
            </Box>
          )}

          {(product?.isSustainable || product?.product?.isSustainable) && (
            <Box bg={"#fff"} rounded={"full"}>
              <Image src={sustainabilityIcon} w={6} h={6} />
            </Box>
          )}
        </Box>
      </Box>

      <Flex direction={"column"} mt={2} width={"100%"} px={4}>
        <Flex>
          <Text color={"primary"}>
            {isAr
              ? cardType === "variation"
                ? product?.product?.nameAr
                : product?.nameAr
              : cardType === "variation"
              ? product?.product?.nameEn
              : product?.nameEn}
          </Text>
        </Flex>
        <Flex>
          <Text mr={1} fontWeight={"bold"}>
            {t("By")} :
          </Text>
          <Link to={`/store-details/${product?.shop?._id}`}>
            <Text color={"primary"}> {product?.shop?.[`name${lang}`]}</Text>
          </Link>
        </Flex>
        {/* <Heading noOfLines={1} as="h3" size="md" mb="2">
          {product?.productName}
        </Heading> */}
        <Text color={"#886969"} mb="2" noOfLines={1}>
          {product?.[`description${lang}`]}
        </Text>

        <Flex justify="flex-start">
          <Text noOfLines={2} color="primary" fontWeight={"bold"} pr={"10px"}>
            {defaultVariation?.defaultPackage?.price?.toString()} {t("AED")}
            <span style={{ fontWeight: "normal", fontSize: 14 }}>
              {defaultVariation?.defaultPackage?.quantity === 1
                ? ""
                : ` /${defaultVariation?.defaultPackage?.quantity} ${t(
                    "units"
                  )}`}
            </span>
          </Text>
          {defaultVariation?.defaultPackage?.originalPrice !==
            defaultVariation?.defaultPackage?.price && (
            <Text noOfLines={2} textDecoration="line-through" color="gray.600">
              {defaultVariation?.defaultPackage?.originalPrice?.toString()}{" "}
              {t("AED")}
            </Text>
          )}
        </Flex>
        {/* minPackage */}
        {product?.product &&
          pathname !== "/" &&
          defaultVariation?.defaultPackage?.price !==
            defaultVariation?.minPackage?.price && (
            <Flex justify="flex-start">
              <Text
                noOfLines={2}
                color="primary"
                fontWeight={"bold"}
                pr={"10px"}
              >
                {defaultVariation?.minPackage?.price?.toString()} {t("AED")}
                <span style={{ fontWeight: "normal", fontSize: 14 }}>
                  {" "}
                  / unit{" "}
                </span>
              </Text>
              {defaultVariation?.minPackage?.originalPrice !==
                defaultVariation?.minPackage?.price && (
                <Text
                  noOfLines={2}
                  textDecoration="line-through"
                  color="gray.600"
                >
                  {defaultVariation?.minPackage?.originalPrice?.toString()}{" "}
                  {t("AED")}
                </Text>
              )}
            </Flex>
          )}

        <Button
          // variant={"outline"}
          bg={"#fff"}
          border={"0.5px solid #000"}
          color={"#000"}
          marginTop={5}
          size="md"
          borderRadius={"20px"}
          // width={"70%"}
          marginX={"auto"}
          mb={2}
        >
          <Link
            to={`/product-details/${
              cardType === "variation" ? product?.product?._id : product?._id
            }`}
          >
            <Flex
              onClick={(e) => {
                // e.preventDefault(); // Prevent the default behavior of anchor links
                window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
              }}
              justify={"center"}
            >
              <Image src={eye} mr={1} />
              <Text> {t("View Product")}</Text>
            </Flex>
          </Link>
        </Button>
      </Flex>
    </Flex>
  );
};

export default TrendingProductCard;
