import React from "react";

import { Box, Button, Flex, Image, SimpleGrid, Text } from "@chakra-ui/react";
import ApiRoutes from "../../api/services/api-routes";
import StoreCard from "../../components/StoreCard";
import TrendingProductCard from "../../components/TrendingProductCard";
import ServiceCard from "../../components/ServiceCard";
import Banners from "../../components/Banners";
import AppCarousel from "../../components/AppCarousel";
import SectionTitle from "../../components/SectionTitle";
import CategoryCard from "../../components/CategoryCard";
import useGetData from "../../api/useGetData";
import { arrowRight, servicesBg } from "../../assets/images";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useLang from "../../hooks/useLang";

const Home = () => {
  const { isAr } = useLang();
  const {
    data: allStoreData,
    loading: getAllShopsLoading,
    onRefresh: onRefreshShops,
  } = useGetData({ route: ApiRoutes?.listFeaturedShops, params: { limit: 6 } });
  const { t } = useTranslation();
  const outerResponsiveForRecentlyAdded = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
      gap: 10,
    },
    moreThan2000: {
      breakpoint: { max: 3000, min: 2000 },
      items: 4,
      gap: 10,
    },
    moreThan1680: {
      breakpoint: { max: 2000, min: 1620 },
      items: 3,
      gap: 10,
    },
    moreThan1500: {
      breakpoint: { max: 1620, min: 1500 },
      items: 3,
      gap: 15,
    },
    moreThan1400: {
      breakpoint: { max: 1500, min: 1400 },
      items: 2,
      gap: 10,
    },
    moreThan1300: {
      breakpoint: { max: 1400, min: 1370 },
      items: 2,
      gap: 10,
    },
    moreThan1200: {
      breakpoint: { max: 1370, min: 1200 },
      items: 2,
      gap: 10,
    },
    moreThan1100: {
      breakpoint: { max: 1200, min: 1100 },
      items: 2,
      gap: 10,
    },
    moreThan1000: {
      breakpoint: { max: 1100, min: 1000 },
      items: 1,
      gap: 10,
    },
    moreThan900: {
      breakpoint: { max: 1000, min: 900 },
      items: 1,
      gap: 10,
    },
    moreThan800: {
      breakpoint: { max: 900, min: 800 },
      items: 1,
      gap: 10,
    },
    moreThan700: {
      breakpoint: { max: 800, min: 700 },
      items: 1,
      gap: 10,
    },
    moreThan600: {
      breakpoint: { max: 700, min: 600 },
      items: 1,
      gap: 10,
    },
    tablet: {
      breakpoint: { max: 600, min: 464 },
      items: 1,
      gap: 10,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      gap: 10,
    },
  };

  const outerResponsiveForCategories = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 8,
      gap: 10,
    },
    moreThan2000: {
      breakpoint: { max: 3000, min: 2000 },
      items: 8,
      gap: 10,
    },
    moreThan1680: {
      breakpoint: { max: 2000, min: 1620 },
      items: 8,
      gap: 10,
    },
    moreThan1500: {
      breakpoint: { max: 1620, min: 1500 },
      items: 8,
      gap: 15,
    },
    moreThan1400: {
      breakpoint: { max: 1500, min: 1400 },
      items: 8,
      gap: 10,
    },
    moreThan1300: {
      breakpoint: { max: 1400, min: 1370 },
      items: 6,
      gap: 10,
    },
    moreThan1200: {
      breakpoint: { max: 1370, min: 1200 },
      items: 6,
      gap: 10,
    },
    moreThan1100: {
      breakpoint: { max: 1200, min: 1100 },
      items: 5,
      gap: 10,
    },
    moreThan1000: {
      breakpoint: { max: 1100, min: 1000 },
      items: 5,
      gap: 10,
    },
    moreThan900: {
      breakpoint: { max: 1000, min: 900 },
      items: 5,
      gap: 10,
    },
    moreThan800: {
      breakpoint: { max: 900, min: 800 },
      items: 5,
      gap: 10,
    },
    moreThan700: {
      breakpoint: { max: 800, min: 700 },
      items: 4,
      gap: 10,
    },
    moreThan600: {
      breakpoint: { max: 700, min: 600 },
      items: 4,
      gap: 10,
    },
    tablet: {
      breakpoint: { max: 600, min: 464 },
      items: 3,
      gap: 10,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      gap: 10,
    },
  };

  return (
    <Flex justifyContent={"center"}>
      <Flex direction={"column"} w={"80%"}>
        <Box>
          <Box zIndex={0}>
            <Banners
              width={""}
              height={"497px"}
              route={ApiRoutes?.listSonnaBanners}

              // route={ApiRoutes.listFeaturedShops}
            />
          </Box>

          {/* =============>categories cards section<============== */}
          <Flex mt={16} maxWidth={"100%"}>
            <AppCarousel
              dataType="Categories"
              sectionTitle="Categories"
              outerResponsive={outerResponsiveForCategories}
              sectionRoute="all-categories"
              params={{ type: "shop" }}
              route={ApiRoutes?.listCategories}
              renderItem={(item) => <CategoryCard category={item} />}
            />
          </Flex>

          {/* =============>Featured Stores section<============== */}
          <Box mt={16}>
            <SectionTitle title="Featured Shops" route="all-stores" />
            <Flex justifyContent="center">
              <SimpleGrid
                columns={{ base: 1, sm: 1, md: 2, lg: 3 }}
                spacing={6}
                width="100%"
              >
                {allStoreData?.result?.map((store: any, index: number) => (
                  <StoreCard
                    width={{ base: "100%", md: "90%" }}
                    key={index}
                    store={store}
                  />
                ))}
              </SimpleGrid>
            </Flex>
          </Box>
          {/* =============>trending products' cards section<============== */}
          <Flex
            mt={8}
            p={{ base: 2, md: 8 }}
            bg={"rgba(246, 244, 244, 0.59)"}
            maxWidth={"100%"}
          >
            <AppCarousel
              dataType="Variations"
              sectionRoute="all-variations"
              sectionSearch={"rank=-1"}
              sectionTitle="Trending Now"
              params={{ rank: -1 }}
              route={ApiRoutes?.listVariations}
              renderItem={(item) =>
                item?.product?.isVerified &&
                !item?.product?.isDeleted &&
                item?.product?.isActive && (
                  <TrendingProductCard
                    width={{ base: "100%", md: "90%" }}
                    product={item}
                    cardType="variation"
                  />
                )
              }
            />
          </Flex>

          {/* =============>recently added products' cards section<============== */}

          <Flex
            alignItems={"flex-start"}
            bg={"rgba(246, 244, 244, 0.59)"}
            mt={16}
            p={{ base: 2, md: 8 }}
            display={{ base: "block", md: "flex" }}
          >
            <Flex
              height={{ base: "auto", md: "400px" }}
              justifyContent={"space-around"}
              // alignItems={"center"}
              direction={"column"}
              width={{ base: "100%", md: "400px" }}
              pr={{ base: 0, md: 8 }}
              mb={{ base: 5, md: 0 }}
            >
              <Text color={"primary"} fontSize={"25px"} fontWeight={"900"}>
                {t("Recently Added")}
              </Text>
              <Text>
                {t(
                  "Check out our recently added products to discover the latest arrivals and exclusive items now available in our store!"
                )}
              </Text>
              <Link
                to={{
                  pathname: "/all-products",
                  search: "sortOrder=-1&sortByDate=creationDate",
                }}
              >
                <Button
                  onClick={(e) => {
                    // e.preventDefault(); // Prevent the default behavior of anchor links
                    // window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
                  }}
                  width={{ base: "auto", md: "60%" }}
                  size={{ base: "sm", md: "lg" }}
                >
                  <Flex>
                    <Text> {t("View All")}</Text>
                    <Image
                      transform={isAr ? "rotate(180deg)" : ""}
                      ml={1}
                      src={arrowRight}
                      width={5}
                    />
                  </Flex>
                </Button>
              </Link>
            </Flex>

            <AppCarousel
              dataType="Products"
              width={{ base: "100%", md: "70%" }}
              outerResponsive={outerResponsiveForRecentlyAdded}
              // sectionRoute="all-products"
              // sectionTitle="Recently Added"
              params={{ sortByDate: "creationDate", sortOrder: -1 }}
              route={ApiRoutes?.listProducts}
              renderItem={(item) => (
                <TrendingProductCard
                  width={{ base: "100%", md: "90%" }}
                  product={item}
                />
              )}
            />
          </Flex>
          {/* ==============================Sonna3 banners==================================== */}
          <Box zIndex={0} display={"flex"} justifyContent={"center"} mt={16}>
            <Banners
              width={""}
              height={"497px"}
              route={ApiRoutes.listFeaturedShops}

              // route={ApiRoutes?.listSonnaBanners}
            />
          </Box>
          {/* =============>Top deals products' cards section<============== */}

          <Flex
            alignItems={"flex-start"}
            bg={"rgba(246, 244, 244, 0.59)"}
            mt={16}
            p={{ base: 2, md: 8 }}
            display={{ base: "block", md: "flex" }}
          >
            <Flex
              height={{ base: "auto", md: "400px" }}
              justifyContent={"space-around"}
              // alignItems={"center"}
              direction={"column"}
              width={{ base: "100%", md: "400px" }}
              pr={{ base: 0, md: 8 }}
              mb={{ base: 5, md: 0 }}
            >
              <Text color={"primary"} fontSize={"25px"} fontWeight={"900"}>
                {t("Top Deals")}
              </Text>
              <Text>
                {t(
                  "Don't miss our Top Deals! Explore unbeatable discounts and special offers on our best-selling products for a limited time only."
                )}
              </Text>
              <Link
                to={{ pathname: "all-products", search: "discountValue=-1" }}
              >
                <Button
                  onClick={(e) => {
                    // e.preventDefault(); // Prevent the default behavior of anchor links
                    // window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
                  }}
                  width={{ base: "auto", md: "60%" }}
                  size={{ base: "sm", md: "lg" }}
                >
                  <Flex>
                    <Text> {t("View All")}</Text>
                    <Image
                      transform={isAr ? "rotate(180deg)" : ""}
                      ml={1}
                      src={arrowRight}
                      width={5}
                    />
                  </Flex>
                </Button>
              </Link>
            </Flex>
            <AppCarousel
              dataType={"Products"}
              width={{ base: "100%", md: "70%" }}
              outerResponsive={outerResponsiveForRecentlyAdded}
              // sectionRoute="all-products"
              // sectionTitle="Top Deals"

              params={{ discountValue: -1 }}
              route={ApiRoutes?.listProducts}
              renderItem={(item) => (
                <TrendingProductCard
                  width={{ base: "100%", md: "90%" }}
                  product={item}
                />
              )}
            />

            {/* <AppCarousel
            width="70%"
            outerResponsive={outerResponsiveForRecentlyAdded}
            // sectionRoute="all-products"
            // sectionTitle="Recently Added"
            params={{ sortByDate: "creationDate", sortOrder: -1 }}
            route={ApiRoutes?.listProducts}
            renderItem={(item) => <TrendingProductCard product={item} />}
          /> */}
          </Flex>

          {/* ========================Services Section======================= */}
          <Flex
            alignItems={"flex-start"}
            bg={"rgba(246, 244, 244, 0.59)"}
            mt={16}
            p={{ base: 2, md: 8 }}
            backgroundImage={servicesBg}
          >
            <AppCarousel
              dataType="Services"
              sectionRoute="all-services"
              sectionTitle="Services"
              route={ApiRoutes?.listServices}
              renderItem={(item) => (
                <ServiceCard
                  width={{ base: "100%", md: "90%" }}
                  service={item}
                />
              )}
            />
          </Flex>

          {/* ==================================InfoSection=============================== */}
        </Box>
      </Flex>
    </Flex>
  );
};

export default Home;
