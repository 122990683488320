import React, { useMemo, useRef, useState } from "react";
import { useAppSelector } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import useGetData from "../../../api/useGetData";
import ApiRoutes from "../../../api/services/api-routes";
import usePostData from "../../../api/usePostData";
import useDeleteData from "../../../api/useDeleteData";
import { lang } from "../../../utils/utilsFunctions";
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import StoreInCart from "../../../components/StoreInCart";
import ProductInCart from "../../../components/ProductInCart";
import Checkout from "../../../components/CartButtons";
import EmptyData from "../../../components/EmptyData";
import { useDispatch } from "react-redux";
import { updateCartTotal } from "../../../redux/reducerSlices/LengthOfCart/lengthOfCartSlice";

export const ProductCart = () => {
  const [collapsedShops, setCollapsedShops] = useState<string[]>([]);
  const customerId = useAppSelector((state) => state?.auth?.authData?._id);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toggleShopCollapse = (shopId: string) => {
    setCollapsedShops((prevCollapsedShops) => {
      if (prevCollapsedShops.includes(shopId)) {
        return prevCollapsedShops.filter((id) => id !== shopId);
      } else {
        return [...prevCollapsedShops, shopId];
      }
    });
  };

  const {
    data,
    setData,
    loading: getCartLoading,
    getData: fetchCartData,
    onRefresh,
  } = useGetData({
    route: ApiRoutes?.getCart,
    params: { customer: customerId },
  });
  const {
    data: postData,
    loading: postCartLoading,
    postData: postCartData,
  } = usePostData();
  const {
    data: postCheckout,
    loading: postCheckoutLoading,
    postData: postCheckoutData,
  } = usePostData();
  const {
    data: deleteData,
    loading: deleteCartLoading,
    deleteData: deleteCartData,
  } = useDeleteData();


  const totalItems = useMemo(() => {
    let total = 0;
    const totalQuantities = data?.result?.subCarts?.map((subCart: any) => {
      total += subCart?.items.reduce(
        (total: any, item: any) => total + item?.quantity,
        0
      );
    });
    return total;
  }, [data]);
  
  const previousCartTotalRef = useRef<number | null>(null); // Track the previous cart total

  const [disableAddButton, setDisableAddButton] = useState(false);
  const handleAddToCart = async (customerId: string, variationId: string) => {
    setDisableAddButton(true); // Temporarily disable to prevent rapid clicks
    await addToCart(customerId, variationId);
    setDisableAddButton(false); // Re-enable after cart is updated
  };
  const addToCart = async (customerId: string, variationId: string) => {
    if (!disableAddButton) {
      const response = await postCartData({
        route: ApiRoutes?.addToCart,
        params: { customer: customerId, item: variationId, quantity: 1 },
        successMsg: t("Added to cart successfully"),
      });

      if (response?.result) {
        const currentCartTotal = response?.result?.cartTotal;

        // Compare current cartTotal with the previous value
        if (currentCartTotal !== previousCartTotalRef.current) {
          dispatch(updateCartTotal(1)); // Update the cart total only if it has changed
        } 

        // Update the reference value to the current cart total
        previousCartTotalRef.current = currentCartTotal;

        // Fetch the latest cart data
        await fetchCartData({
          route: ApiRoutes?.getCart,
          params: { customer: customerId },
        });
      }
    }
  };

  const [disableRemoveButton, setDisableRemoveButton] = useState(false);
  const handleRemoveFromCart = async (
    customerId: string,
    variationId: string,
    shopId: string,
    quantity: number
  ) => {
    setDisableRemoveButton(true); // Temporarily disable to prevent rapid clicks
    await removeFromCart(customerId, variationId, shopId, quantity);
    setDisableRemoveButton(false); // Re-enable after cart is updated
  };
  const removeFromCart = async (
    customerId: string,
    variationId: string,
    shopId: string,
    quantity: number
  ) => {
    if (disableRemoveButton) return; // Prevent multiple calls

    if (!disableRemoveButton) {
      const response = await deleteCartData({
        route: ApiRoutes?.removeFromCart,
        params: {
          customer: customerId,
          item: variationId,
          quantity: quantity,
          shop: shopId,
        },
      });

      if (!response?.error) {
        const currentCartTotal = response?.result?.cartTotal;

        // Compare current cartTotal with the previous value
        if (currentCartTotal !== previousCartTotalRef.current) {
          dispatch(updateCartTotal(-quantity));// Update the cart total only if it has changed
        } 
        // Update the reference value to the current cart total
        previousCartTotalRef.current = currentCartTotal;

        // Fetch the latest cart data
        fetchCartData({
          route: ApiRoutes?.getCart,
          params: { customer: customerId },
        });

        
      }
    }
  };

  // const removeFromCart = async (
  //   customerId: string,
  //   variationId: string,
  //   shopId: string,
  //   quantity: number
  // ) => {
  //   if (disableRemoveButton) return; // Prevent multiple calls

  //   setDisableRemoveButton(true);

  //   const response = await deleteCartData({
  //     route: ApiRoutes?.removeFromCart,
  //     params: {
  //       customer: customerId,
  //       item: variationId,
  //       quantity: quantity,
  //       shop: shopId,
  //     },
  //   });

  //   if (response?.result) {
  //     await fetchCartData({
  //       route: ApiRoutes?.getCart,
  //       params: { customer: customerId },
  //     });
  //     dispatch(updateCartTotal(-quantity)); // Ensure correct decrease
  //   }

  //   setDisableRemoveButton(false);
  // };

  const emptyCart = async (customerId: string) => {
    const response = await deleteCartData({
      route: ApiRoutes?.flushCart,
      params: { customer: customerId },
    });
    if (!response?.error) {
      fetchCartData({
        route: ApiRoutes?.getCart,
        params: { customer: customerId },
      });
      dispatch(updateCartTotal(-totalItems));
    }
  };

  const checkoutCartFn = async (cartId: string) => {
    const currentDate = new Date();
    const isoFormattedDate = currentDate.toISOString();

    const response = await postCheckoutData({
      route: ApiRoutes?.cartOrder,
      params: { cart: cartId },
      data: { customer: customerId, issueDate: isoFormattedDate },
      successMsg: t("Checkout successfully"),
    });
    if (!response?.error) {
      fetchCartData({
        route: ApiRoutes?.getCart,
        params: { customer: customerId },
      });
      dispatch(updateCartTotal(-totalItems));
    }
  };

  return (
    <Flex direction={"column"} mb={40}>
      {data?.result?.subCarts ? (
        data?.result?.subCarts.length > 0 ? (
          <>
            <Flex direction={"column"} align={"center"}>
              <Stack width={"75%"} spacing={4} paddingTop={10} flex={1}>
                <Checkout
                  cashback={data?.result?.customer?.cashback}
                  cartOriginalTotal={data?.result?.cartOriginalTotal}
                  cartId={data?.result?._id}
                  isFood={false}
                  checkoutCartFn={checkoutCartFn}
                  cartTotal={data?.result?.cartTotal}
                  customerId={customerId}
                  emptyCartFn={() => emptyCart(customerId)}
                  hasPurchased={data?.result?.customer?.hasPurchased}
                />

                {data?.result?.subCarts?.map((subCart: any, index: any) => (
                  <Box
                    width={"100%"}
                    // height={300}
                    key={subCart?.shop?._id}
                    // onClick={() => toggleShopCollapse(subCart?.shop?._id)}
                  >
                    <StoreInCart
                      onRefresh={onRefresh}
                      usedCashback={subCart?.usedCashback}
                      storeId={subCart?.shop?._id}
                      width={"100%"}
                      collapsedShops={collapsedShops}
                      storeName={subCart?.shop?.[`name${lang}`]}
                      storeImage={subCart?.shop?.image?.Location}
                      price={subCart?.shopTotal}
                      onToggleCollapse={() =>
                        toggleShopCollapse(subCart?.shop?._id)
                      }
                      index={index}
                      data={data}
                      setData={setData}
                      food={false}
                      coupon={subCart?.coupon}
                      originalPrice={subCart?.shopOriginalTotal}
                    />

                    {!collapsedShops.includes(subCart?.shop?._id) && (
                      <Stack align={"center"} spacing={4}>
                        {subCart?.items?.map((item: any) => (
                          <ProductInCart
                            width={"90%"}
                            key={item?.variation?._id}
                            productName={item?.product?.[`name${lang}`]}
                            itemTotal={item?.itemTotal}
                            variationPrice={
                              item?.variation?.minPackage?.price ||
                              item?.variation?.minPackage?.originalPrice
                            }
                            variationDesc={
                              item?.variation?.[`description${lang}`]
                            }
                            variationImage={
                              item?.variation?.images?.[0]?.Location
                            }
                            quantity={item?.quantity}
                            fields={item?.variation?.fields}
                            onDeleteProduct={
                              () =>
                                handleRemoveFromCart(
                                  customerId,
                                  item?.variation?._id,
                                  subCart?.shop?._id,
                                  1
                                )
                              // removeFromCart(
                              //   customerId,
                              //   item?.variation?._id,
                              //   subCart?.shop?._id,
                              //   1
                              // )
                            }
                            onDeleteAll={() => {
                              handleRemoveFromCart(
                                customerId,
                                item?.variation?._id,
                                subCart?.shop?._id,
                                item?.quantity
                              )
                              // removeFromCart(
                              //   customerId,
                              //   item?.variation?._id,
                              //   subCart?.shop?._id,
                              //   item?.quantity
                              // );
                              // dispatch(updateCartTotal(-item?.quantity));
                              // dispatch(updateCartTotal(-item?.quantity));
                            }}
                            disableAddButton={disableAddButton}
                            disableRemoveButton={disableRemoveButton}
                            // onAddToCart={() =>
                            //   addToCart(customerId, item?.variation?._id)
                            // }
                            onAddToCart={() =>
                              handleAddToCart(customerId, item?.variation?._id)
                            }
                          />
                        ))}
                      </Stack>
                    )}
                  </Box>
                ))}
              </Stack>
            </Flex>
          </>
        ) : (
          <EmptyData />
        )
      ) : (
        <Text color={"primary"} fontSize={20} textAlign={"center"}>
          {t("Loading...")}
        </Text>
      )}
    </Flex>
  );
};
