import React, { ReactNode, useEffect } from "react";
import { store, useAppSelector } from "./../../redux/store";
import { Outlet, useLocation } from "react-router-dom";

import SearchHeader from "./Navbar/components/SearchHeader";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer";
import { Box, Flex, Text } from "@chakra-ui/react";
import { mainBackground } from "../../assets/images";
import CategoriesNav from "./CategoriesNav";
import CategoriesHeader from "../CategoriesHeader";
import ChatSpace from "../Chat";
import CategoriesDrawer from "./CategoriesDrawer";

interface LayoutProps {
  // children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({}) => {
  const token = store.getState().auth.token;
  const customerId = useAppSelector((state) => state?.auth?.authData?._id);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);


  // const routeName = location.pathname.substring(1) || 'Home';
  const routeName = location.pathname.split("/")[1] || "Home";

  useEffect(() => {
    switch (routeName.toLowerCase()) {
      case "home":
        document.title = `Sonna3-Home`;
        break;
      case "all-products":
        if (searchParams.get("discountValue") =="-1") {
          document.title = `Sonna3-Top Deals`;
        } else if (searchParams.get("sortOrder") == "-1") {
          document.title = `Sonna3-Recently Added`;
        } else {
          document.title = `Sonna3-Products`;
        }
        break;
      case "all-variations":
        document.title = `Sonna3-Trending Now`;
        break;
      case "all-categories":
        document.title = `Sonna3-Categories`;
        break;
      case "all-stores":
        document.title = `Sonna3-Shops`;
        break;
      case "all-services":
        document.title = `Sonna3-Services`;
        break;
      case "profile":
        document.title = `Sonna3-Profile`;
        break;
        case "cart":
        document.title = `Sonna3-Cart`;
        break;
        case "wishlist":
        document.title = `Sonna3-Wishlist`;
        break;
        case "comparelist":
        document.title = `Sonna3-CompareList`;
        break;
      default:
        document.title = `Sonna3-Customer`;
    }
  }, [routeName, searchParams]);

  return (
    <Box>
      <Box
        position={"sticky"}
        top={0}
        width={"100%"}
        zIndex={1001}
        bgSize={"contain"}
      >
        <Navbar />

        <Flex display={{ base: "flex", md: "block" }}>
          <CategoriesDrawer />
          <SearchHeader />
        </Flex>

        <CategoriesNav />
      </Box>
      <Box pt={{}}>
        <Outlet />
      </Box>
      <Footer />
      {token && customerId !== "guest" && <ChatSpace />}
    </Box>
  );
};

export default Layout;
