import {
  Badge,
  Box,
  Button,
  Flex,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { lang, showMSG } from "../utils/utilsFunctions";
import { useTranslation } from "react-i18next";
import { ChatIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import chatMenu, {
  setChatMenuData,
} from "../redux/reducerSlices/ChatMenu/chatMenu";
import Axios, { BASE_END_POINT } from "../api/services/axios";
import { useAppSelector } from "../redux/store";
import { Navigate, useNavigate } from "react-router-dom";
import { updateCartTotal } from "../redux/reducerSlices/LengthOfCart/lengthOfCartSlice";
import useGetData from "../api/useGetData";

const statusColors = {
  cancled: "#f6cbc6",
};

const ServiceInCart = ({ service }: { service: any }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const customerId = useAppSelector((state) => state?.auth?.authInfo?._id);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [canceled, setCanceled] = useState(false);

  const {
    loading,
    data,
    getData: fetchCartData,
    loadMoreData,
    loadingMoreData,
  } = useGetData({
    route: "requests/listCart",
    params: {
      customer: customerId,
      sortByDate: "issueDate",
      sortOrder: -1,
    },
  });

  const handleCancelRequest = async (_id: string, customer: string) => {
    setCancelLoading(true);
    try {
      const response = await Axios.patch(
        `${BASE_END_POINT}requests/cancel?_id=${_id}&customer=${customerId}`
      );
      setCanceled(true);

      if (response?.data?.success) {
        dispatch(updateCartTotal(-1));
        fetchCartData({
          route: "requests/listCart",
          params: {
            customer: customerId,
            sortByDate: "issueDate",
            sortOrder: -1,
          },
        });
      }
    } catch (error: any) {
      // showMSG(toast, "error", error);
    }
    setCancelLoading(false);
  };

  console.log(service);
  return (
    <>
      <Box
        onClick={() => {
          navigate(`/service-details/${service?.service?._id}`);
        }}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        boxShadow="lg"
        mb={8}
        width={"100%"}
        // height={220}
        px={{ base: 0, md: 8 }}
        overflowY={"scroll"}
        cursor={"pointer"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
          },

          "&::-webkit-scrollbar-thumb": {
            background: "#EAC07F",

            borderRadius: 8,
          },
        }}
      >
        <Flex
          width={"100%"}
          display={{ base: "block", md: "flex" }}
          height={"100%"}
          gap={2}
        >
          <Box
            backgroundSize={"contain"}
            backgroundRepeat={"no-repeat"}
            backgroundPosition={"center"}
            backgroundImage={service?.service?.images?.[0]?.Location}
            bgColor={"secondary"}
            width={{ base: "100%", md: "40%" }}
            paddingTop={"10"}
            height={{ base: 200, md: "100%" }}
          ></Box>

          <Flex width={{ base: "100%", md: "60%" }} direction={"column"}>
            <Flex
              mx={{ base: 2, md: 0 }}
              display={{ base: "block", md: "flex" }}
              gap={3}
              py={3}
              width={"100%"}
            >
              <Flex
                direction={"column"}
                //   p={4}
                height={"100%"}
                justifyContent={"space-between"}
                // w={"100%"}
                flex={1}
              >
                <Flex direction={"column"}>
                  <Flex direction="column">
                    <Text color={"primary"} fontSize={18} fontWeight={"600"}>
                      {service?.service?.[`name${lang}`]}
                    </Text>
                    <Text fontSize={14}>
                      {t("By")} :
                      <span style={{ color: "#a4020c", marginLeft: "4px" }}>
                        {service?.shop?.[`name${lang}`]}
                      </span>
                    </Text>
                    <Text mt={2} fontSize={16} color={"#767070"}>
                      {service?.service?.[`description${lang}`]}
                    </Text>
                    <Flex
                      display={{ base: "block", md: "flex" }}
                      w={"100%"}
                      mt={2}
                      justify={"space-between"}
                    >
                      <Text color={"primary"} fontSize={18}>
                        {t("Base Price")} : {service?.service?.basePrice}{" "}
                        {t("AED")}
                      </Text>

                      {service?.serviceTotal && (
                        <Text color={"primary"} fontSize={18}>
                          {t("Service Total")} : {service?.serviceTotal}{" "}
                          {t("AED")}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
                <Flex display={{ base: "block", md: "flex" }} wrap={"wrap"}>
                  {service?.fields?.map((field: any, index: any) => (
                    <Flex>
                      <Text style={{ padding: 5 }} color={"primary"}>
                        {field?.field?.[`name${lang}`]} :
                      </Text>
                      <Text style={{ padding: 5 }}>
                        {field?.value?.[lang?.toLowerCase()] ||
                          field?.value?.number?.toString()}
                      </Text>
                      {index !== service?.fields?.length - 1 && (
                        <Box>
                          <Text color={"secondary"}>|</Text>
                        </Box>
                      )}
                    </Flex>
                  ))}
                </Flex>
              </Flex>
              <Box textAlign={{ base: "center", md: "inherit" }}>
                <Badge
                  textAlign={"center"}
                  width={"90%"}
                  colorScheme={
                    canceled
                      ? "red"
                      : service?.status === "pending"
                      ? "yellow"
                      : service?.status === "rejected" ||
                        service?.status === "canceled"
                      ? "red"
                      : "green"
                  }
                  borderRadius={13}
                  py={2}
                  px={6}
                  mb={4}
                  color={"primary"}
                  // textTransform="none"
                >
                  {t(
                    canceled ? "canceled" : service?.status?.toLocaleLowerCase()
                  )}
                </Badge>

                <Box my={3}>
                  <Button
                    width={"90%"}
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(
                        setChatMenuData({
                          chatMenuData: {
                            active: true,
                            chatMenu: true,
                            list: true,
                            chatBox: true,
                            withAdmin: false,
                            fastBox: true,
                            chatType: "customers",
                            sellerId: service?.seller,
                            roomId: "",
                          },
                        })
                      );
                    }}
                  >
                    <Text mx={1}>{t("Chat with")}</Text>
                    <ChatIcon />
                  </Button>
                </Box>
              </Box>
            </Flex>

            <Flex mb={4} justify={"center"}>
              <Flex justify={"center"} width={"100%"}>
                {(service?.status === "accepted" ||
                  service?.status === "pending") &&
                  !canceled && (
                    <Button
                      width={"25%"}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCancelRequest(service?._id, customerId);
                      }}
                      bgColor={"primary"}
                      isLoading={cancelLoading}
                    >
                      {t("Cancel")}
                    </Button>
                  )}
                {service?.status === "accepted" && !canceled && (
                  <Button
                    ml={4}
                    disabled={true}
                    width={"25%"}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/service-checkout?_id=${service._id}`);
                    }}
                  >
                    {t("Checkout")}
                  </Button>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default ServiceInCart;
